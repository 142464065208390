import { Typography } from "@material-ui/core";
import { getCfg } from "../Core/Services/ConfigService";
import React from "react";
import "./Footer.css";

const Footer: React.FC = (): React.ReactElement => {

  const [cfg, setCfg] = React.useState<{ copyright: string | null }>({
	  copyright: null
  });
  React.useEffect(() => {
    getCfg().then((config) => {
      if (config) {
        setCfg(config);
      }
    });
  }, []);

  return (
        <div className="footerContainer">
            <Typography variant="caption" component="p">
                &copy; { cfg["copyright"] ? cfg["copyright"] : "2024 The Hartford" }
            </Typography>
        </div>
  );
};

export default Footer;
